/**
 *  Main CSS file for HIS
 *  
 *  @author Sanjay
 *  @version 1.0.0
 *  @copyright InnoCirc Ventures Pvt. Ltd.
 */
@import 'variables';
@import 'mixins';
@import 'layout';
@import 'default';
@import 'header';
@import 'aside-nav';
@import 'form';
@import 'table';
@import 'plugins';
@import 'modal';
@import 'mpi-registration.scss';
@import 'magic-search.scss';
@import 'icons';
@import 'timeline';

.btn-plus-icon{
    background: #8b8b8b !important;
    color: #fff !important;
    border-radius: 0px !important;
    padding: 4px 8px !important;
    width: 30px;
    height: 30px !important;
    text-align: center !important;
    margin-bottom: 10px;
}
.taperinput100{
    width: 68px !important;
    }
    .taperinput200{
        width: 120px !important;
    }
    .taperinput300{
        width: 250px !important;
        .ant-select-selector{
            overflow-x: auto !important;
            &::-webkit-scrollbar {
                width: 2px;
              }
              &::-webkit-scrollbar-thumb {
                background-color: #ccc;
                outline: 1px solid #ccc;
              }
        }
    
    }


.emrfrm.ncpselect .ant-select-selector { height: auto !important; }
.custom-grid-scroll .ag-center-cols-viewport{
    overflow-x: scroll !important;
}